@use "src/css/var/var.scss" as *;
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

body {
    /* stylelint-disable-next-line selector-no-qualifying-type */
    &.previewIframe {
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
